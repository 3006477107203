import React from "react"

import { Container } from 'reactstrap' 
import LayoutEsp from '../../components/layoutEsp'
import SEO from "../../components/seo"
import Slider from '../../components/slider-about' 
import worldIcon from '../../assets/images/aboutus-surpasing.png'
import reduser from '../../assets/images/red-user.png' 
import redMission from '../../assets/images/red-mission.png' 
import redVision from '../../assets/images/red-vision.png' 
import redValue from '../../assets/images/red-value.png' 
import qualityIcon from '../../assets/images/quality-value.png' 
import safetyIcon from '../../assets/images/safety-value.png' 
import honestyIcon from '../../assets/images/honesty-value.png' 
import puntualityIcon from '../../assets/images/puntuality-value.png' 
import techIcon from '../../assets/images/tech-value.png' 
 
export default () => (
  <LayoutEsp>
    <SEO title="Nosotros" />
    <Slider title="NOSOTROS" />
	<div className="world-brand" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="world-icon" src={worldIcon} alt="worldIcon"/>
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
			  	<h4 className="surpassing"><b>SOBREPASANDO</b> TODAS LAS FRONTERAS DE <b>NOSOTROS</b> A <b>TI</b></h4>  
			  </div>
			</div>
		</Container>
	</div>
	<div className="user-background" >
		<Container className="pt-4">
			<div className="row block">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
				<img className="redUser" src={reduser} alt="red icon user"/> 
				<h4 className="aboutH">{'NOSOTROS'}</h4> 
				<p className="aboutP">{'Conocenos'}</p>  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h4 className="grayH">{' '}</h4> 
						<p className="grayText">
							 Gutierrez Trucking LLC. / Transportes Internacionales Escoboza S. De R.L. de C.V.
							 fue fundada el 02 de junio de 2002, iniciando sus operaciones en septiembre del mismo año,
							 contando con una flotilla de 12 tractores, 12 furgonetas y 2 plataformas.<br/><br/>
							
							 Brindamos servicios de transporte a nivel local entre México y EE. UU., Especializándonos solo en los 12 estados del oeste.<br/><br/>
							
							Hemos incrementado considerablemente nuestros clientes como nuestras operaciones y equipos. Hoy tenemos una flotilla de
							125 tractores, 288 camionetas secas de 53 ", 43 plataformas planas de 48", 2 paneles, 2 camiones bobtail de 24 ",
							plataforma de 2 escalones para peso normal, 8 vertederos finales y 2 contenedores frigoríficos, ahora proporcionamos transporte
							servicios en los estados del norte de México, todo USA y CANADÁ.<br/><br/>
						</p>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div>
	<div className="mision-background" >
		<Container className="pt-4 ">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 locations-block locations-set">
				<img className="redMission" src={redMission} alt="red icon mission"/> 
				<h4 className="missionH">{'Mission'}</h4> 
				<p className="missionP">{'Alta Calidad'}</p>  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row"> 
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
						<h4 className="whiteH">{' '}</h4> 
						<p className="whiteText"> 
								Somos una empresa de servicios de transporte, nuestra misión es  <b>satisfacer </b> las necesidades de nuestros clientes de manera  <b>oportuna y segura </b>, 
								aprovechando nuestra experiencia para brindar la servicio de máxima calidad.
						</p>
					</div> 
				</div>
			  </div> 
			</div>
		</Container>
	</div>
	<div className="vision-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
				<img className="redVision" src={redVision} alt="red icon mission"/> 
				<h4 className="visionH">{'Vision'}</h4> 
				<p className="visionP">{'Ligerazgo'}</p>  
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<h4 className="grayH2">{' '}</h4> 
						<p className="grayText"> 
									Ser el líder en transporte seguro de carga, nacional e internacional, brindando un
									servicio <b> eficiente y dedicado </b>, asegurando el desarrollo del talento humano que trabaja con
									nosotros para el lucro de la empresa y la participación activa hacia nuestra comunidad. 
						</p>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div> 
	<div className="red-cross-background" >
		<Container className="pt-4">
			<div className="row">
			  <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12"> 
					<img className="redValue" src={redValue} alt="red icon value"/> 
					<h4 className="valuesH">{'Valores'}</h4>  
					<p className="valuesP">{'Servir'}</p>   
			  </div>
			  <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 aboutusblock">
				<div className="row espacioBlanco"> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="qualityIcon" src={qualityIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Calidad'}</h4> 
						<p className="whiteVText">
							{'Más de 17 años de experiencia en el campo del transporte de carga '+
							 'nos avalan y por eso la calidad en nuestro servicio es de primordial importancia.'  
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="safetyIcon" src={safetyIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Seguridad'}</h4> 
						<p className="whiteVText">
							{'Conocemos la importancia de la seguridad y nos esforzamos por mantener '+
							 'la confianza de nuestros clientes. Cumplimos con los estándares y utilizamos '+  
							 'herramientas que nos permiten el máximo nivel de seguridad en todos los traslados que realizamos.'  
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="honestyIcon" src={honestyIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Honestidad'}</h4> 
						<p className="whiteVText">
							{'El mejor interés de nuestros clientes es lo primero, por eso optimizamos '+
							 'los recursos y nos adherimos a las políticas de servicio cumpliendo eficientemente '+  
							 'con cada uno de nuestros compromisos.'  
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="puntualityIcon" src={puntualityIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Puntualidad'}</h4> 
						<p className="whiteVText">
							{'Entendemos el valor y la importancia del tiempo, apoyados '+
							 'por nuestro equipo de logística, nos aseguramos de entregar siempre a tiempo.' 
							 } 
						</p>
					</div> 
				</div>
				<div className="row valueLine">
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
						<img className="techIcon" src={techIcon} alt="white icon value"/> 
						<h4 className="whiteVH">{'Tecnologia'}</h4> 
						<p className="whiteVText">
							{'Nos mantenemos a la vanguardia de la tecnología, manteniendo la página '+
							 'con todos los avances tecnológicos que enfrentamos todos los días en esta '+ 
							 'industria para que nuestros envíos lleguen de manera oportuna y segura.' 
							 } 
						</p>
					</div> 
				</div>
			  </div>
			</div>
		</Container>
	</div>  
  </LayoutEsp>
)
